import { useState } from 'react'
import emailjs from 'emailjs-com'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialState = {
  name: '',
  email: '',
  message: '',
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

  

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  
  const classes = useStyles();
  const [open, setOpen] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })
  
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
       //'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
       handleClick()
      )
      .then(
        (result) => {
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    
    <div>
      <div id='contact'>

        <div className='container'>

          <div className='col-md-8'>
            <div className='row'>

              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please write us an email over info@ravepull.uk
                </p>
              </div>
              
            </div>
          </div>
          
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            {
              /*
      <div className='contact-item'>
      <p>
      <span>
          <i className='fa fa-phone'></i> Phone
        </span>{' '}
        {props.data ? props.data.phone : 'loading'}
      </p>
    </div>
            */ }
      
            
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i>Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                 {/*  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                 */}
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className={classes.root}>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            
              <Alert onClose={handleClose} severity="success" >YOUR MESSAGE SENDED</Alert>
          </Snackbar>
      </div>  
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; Copyright RavePull Company, all rights reserved.
          </p>
        </div>
      </div>
    </div>
    
  )
}
